import router from "@/router"
import axios from "axios"
import { currentCallStore } from "@/store/services/callStore"
import store from "../../store"
import { callWrapUpUrl } from "./index"

export const COUNTERPART_DATA_LOADER_LABEL = "counterpart_data_loader"

export function pushOrReplaceRoute (routeUrl) {
  // The route has to be replaced when the application is in an iFrame which would be true if window.self !== window.top
  if (window.self !== window.top) return router.replace(routeUrl)
  else return router.push(routeUrl)
}

/**
 * Returns the call id from the URL, requires a Vue Router (imported from @/router)
 * */
export function getCallIdFromUrl () {
  if (!router || !router.currentRoute || !router.currentRoute.params) return null
  return router.currentRoute.params.callId
}

/** Pushes the provided value as endConversation flag into the URL as a query param */
export function setEndConversation ({ dispatch }, value) {
  return new Promise((resolve, reject) => {
    const callId = getCallIdFromUrl()
    const url = value ? `${callWrapUpUrl}/${callId}` : `/conversation/${callId}`
    const query = router.currentRoute.query
    const newRoute = { path: url, query }
    pushOrReplaceRoute(newRoute).then(() => {
      resolve()
    }).catch(error => {
      dispatch("handleError", error.response)
      reject(error)
    })
  })
}

/** Utils function to map the provided field list (list of strings) to the currentCallStore (imported) */
export function mapStateToCurrentCallStore (fieldList) {
  const result = {}
  fieldList.forEach(fieldString => {
    result[fieldString] = function () {
      return currentCallStore[fieldString]
    }
  })
  return result
}

export const loadCounterpartData = async (counterpart) => {
  const url = "/api/counterpartsnew/get_counterpart?service=" + counterpart.crm_service
  try {
    const { data } = await axios.post(url, counterpart)
    const newCounterpart = { ...data, crmDataLoaded: true, index: counterpart.index }

    // update downloaded data with additional data from API for lookup fields
    const { getCrmLookupFieldsInPlaybook } = currentCallStore

    if (Object.keys(getCrmLookupFieldsInPlaybook).length > 0) {
      for (const [key, value] of Object.entries(getCrmLookupFieldsInPlaybook)) {
        const lookupFieldName = key.split("-")[1]
        const crmId = newCounterpart.external_resource[lookupFieldName]
        if (crmId) {
          newCounterpart.external_resource[lookupFieldName] = await findCRMLookupFieldValueById(counterpart.crm_service, value, crmId)
        }
      }
    }
    return newCounterpart
  } catch (error) {
    console.error(error)
  }
}

export const checkIfLoadedCrmFieldsExist = async (counterparts) => {
  for await (const counterpart of counterparts) {
    if (counterpart) {
      const crmService = counterpart.crm_service
      const crmObjectType = counterpart.type
      const serviceTypeCombination = `${crmService}-${crmObjectType}`
      if (currentCallStore.loadedCrmFields[serviceTypeCombination] && currentCallStore.loadedCrmFields[serviceTypeCombination].count) {
        return true
      }
    }
  }
  return false
}

export const getCounterpartsData = async ({ counterparts, selectedCounterparts, type }) => {
  const promises = []
  const counterpartsWithLoadedData = {}
  let counterpartsWithLoadedDataCount = 0

  // This is to create the promise to get Crm Data of all selected counterparts at once
  if (type === COUNTERPART_DATA_LOADER_LABEL) {
    for (const counterpart of counterparts) if (counterpart) promises.push(loadCounterpartData(counterpart))
  } else {
    for (let [index, counterpart] of counterparts.entries()) {
      if (counterpart) {
        counterpart = { ...counterpart, index }
        if (store.getters["counterpartStore/CRMSupportsLoadingOfData"](counterpart)) promises.push(loadCounterpartData(counterpart))
      }
    }
  }

  const promiseResults = await Promise.allSettled(promises)
  promiseResults.forEach((result) => {
    if (result.status === "fulfilled" && !!result.value) {
      const newCounterpart = result.value
      const counterpartIndex = newCounterpart.index
      const counterpartCRM = newCounterpart.crm_service
      const counterpartType = newCounterpart.type
      counterpartsWithLoadedData[`${counterpartCRM}-${counterpartType}`] = newCounterpart
      counterpartsWithLoadedDataCount++
      selectedCounterparts.splice(counterpartIndex, 1, newCounterpart)
    }
  })

  return { counterpartsWithLoadedDataCount, counterpartsWithLoadedData, selectedCounterparts }
}

export const findCRMLookupFieldValueById = async (crmService, lookupFieldType, crmId) => {
  try {
    const url = `/api/oauthservices/${crmService}/get_lookup_value_by_id/`
    const { data } = await axios.get(url, {
      params: {
        type: lookupFieldType,
        crm_id: crmId
      }
    })
    return data
  } catch (error) {
    console.debug(error)
  }
}

export const checkIfParentDomainIsAllowed = (url) => {
  // Messages from bao swift is only accepted on specific domains for security
  // reasons. So this checks that the message is coming from an accepted domain
  const domain = new URL(url).hostname
  const supportedDomains = [
    "hubspot.com", "lightning.force.com", "pipedrive.com", "dynamics.com", "zendesk.com",
    "app.salesloft.com", "sugarcrm", "meet.google.com", "my.salesforce.com"
  ]
  const domainRegex = new RegExp(supportedDomains.join("|"))
  return domainRegex.test(domain)
}

export const isValidMeetingUrl = (url) => {
  // regular expression to match video meet url
  // 1. google meet's url - https://meet.google.com/abc-defg-hik?...
  const meetDomainRegex = /^https:\/\/meet\.google\.com\/[a-zA-Z]{3}-[a-zA-Z]{4}-[a-zA-Z]{3}(\?|$)/
  return meetDomainRegex.test(url)
}
