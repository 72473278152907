<template lang="html">
  <router-view />
</template>

<script>
import CallSetupMixin from "@/apps/call/CallSetupMixin"
import { checkIfParentDomainIsAllowed } from "@/apps/call/utils"

export default {
  name: "App",
  mixins: [CallSetupMixin],
  mounted () {
    // This event listener is to listen to events from the browser extension
    window.addEventListener("message", (event) => this.handleBaoSwiftMessages(event))
  },
  methods: {
    handleBaoSwiftMessages (event) {
      const messageData = event.data
      const isDomainAllowed = checkIfParentDomainIsAllowed(event.origin)
      if (isDomainAllowed) {
        this.handleSpecificBaoSwiftMessages(messageData)
      }
    },
    handleSpecificBaoSwiftMessages (messageData) {
      switch (messageData.type) {
        case "quick_start_call":
          this.handleQuickStartTriggered(messageData)
          break

        case "reload_application":
          window.location.reload()
          break
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener("message", (event) => this.handleBaoSwiftMessages(event))
  }
}
</script>
